import { refreshToken } from "helpers/auth/refreshToken";
import { head } from "lodash";
import { json } from "react-router-dom";
const { toast: toastr } = require("react-toastify");

class FetchAPI {
    constructor() {
        if (FetchAPI.instance) {
            return FetchAPI.instance;
        }
        FetchAPI.instance = this;
    }

    setHeaders(apiKey) {
        this.accessToken = localStorage.getItem("accessToken");
        this.customHeaders = {
            "Content-Type": "application/json",
            "Accept": "application/json",
        }
        if (this.accessToken) {
            this.customHeaders['Authorization'] = "Bearer " + this.accessToken
        }
    }

    async handleResponse(response, endpoint, body = null) {
        if (response.status < 400) {
            return await response.json();
        }

        if (response.status == 500) {
            throw Error("Server Error")
        }

        if (response.status == 404) { // resource not found
            throw Error("Resource not found")
        }

        const res = await response.json();

        if (response.status == 403) { // has no permission
            throw Error(res.detail)
        }

        if (response.status == 400) { // validation errors mainly
            if (res.detail) {
                throw Error(res.detail)
            }
            if (Object.keys(res).length > 0 && typeof res === 'object') {
                throw Error(`${Object.keys(res)[0]}: ${res[Object.keys(res)[0]]}`)
            }
            throw Error(JSON.stringify(res))
        }

        if (res.code == "token_not_valid") {
            try {
                let token = await refreshToken();
                this.accessToken = token;
                localStorage.setItem("accessToken", token);
                if (body) {
                    return this.post(endpoint, body);
                } else {
                    return this.get(endpoint);
                }
            } catch (error) {
                toastr.error(error);
                setTimeout(() => window.location.href = '/login', 1000);
            }
        } else {
            toastr.error(res.detail);
            // window.location.href = '/login';
        }
    }

    async get(endpoint, apiKey) {
        this.setHeaders(apiKey);
        const response = await fetch(endpoint, { headers: this.customHeaders });
        return this.handleResponse(response, endpoint);
    }

    async post(endpoint, body, apiKey) {
        const response = await fetch(endpoint, {
            method: "POST",
            body: JSON.stringify(body),
            headers: this.customHeaders
        });
        return this.handleResponse(response, endpoint, body);
    }

    async uploadFile(endpoint, formData, apiKey) {
        const accessToken = localStorage.getItem("accessToken");
        const customHeaders = {
          "Accept": "application/json",
        //   "x-api-key": apiKey,
        };
      
        if (accessToken) {
          customHeaders["Authorization"] = "Bearer " + accessToken;
        }
      
        const response = await fetch(endpoint, {
          method: "POST",
          body: formData,
          headers: customHeaders,
        });
      
        return this.handleResponse(response, endpoint, formData);
    }

    async upload(endpoint, body, apiKey, filename) {
        let myHeaders = {};
        myHeaders['Content-disposition'] = `attachment; filename=${filename}`;
        myHeaders['x-api-key'] = apiKey;


        const response = await fetch(endpoint, {
            method: "POST",
            body: body,
            headers: myHeaders
        });
        console.log(response)
        return this.handleResponse(response, endpoint, body);

    }

    async delete(endpoint, id, apiKey) {
        this.setHeaders(apiKey);
        const response = await fetch(`${endpoint}`, {
            method: "DELETE",
            headers: this.customHeaders
        });

        if (response.status == 204) {
            return true;
        }

        return this.handleResponse(response, endpoint);
    }

    async put(endpoint, payload, id, apiKey) {
        const response = await fetch(`${endpoint}/${id}`, {
            method: "PUT",
            headers: this.customHeaders,
            body: JSON.stringify(payload)
        });
        return this.handleResponse(response, endpoint, payload);
    }
}
const fetchAPI = new FetchAPI();

export default fetchAPI;
