//Import Components
import CampaignDetail from "./campaignDetail";
import { getCustomerId, getUserId } from 'helpers/auth/getUserId';


import {
  Col,
  Row,
  Container,
} from "reactstrap";
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query';
import React, { useState } from "react";
import fetchAPI from 'helpers/fetcher/fetchClient';
import OverAllInsights from "../MainComponents/OverAllInsights";
import MonthlyDetectedRemoved from "../MainComponents/MonthlyDetectedRemoved";
import TopRemovals from "../MainComponents/TopRemovals";
import ServicesData from "../MainComponents/servicesData";

const DashboardSaas = props => {
  let { campaign_id } = useParams()
  const [campaignData, setCampaignData] = useState(null);

  const fetchCampaignInfo = async () => {
    const data = await fetchAPI.get(
      process.env.REACT_APP_CUSTOMERS_MANAGEMENT_URL +
      '/analytics/customer/' + getCustomerId() + '?campaign_id=' + campaign_id,
      process.env.REACT_APP_CAMPAIGNS_MANAGEMENT_TOKEN
    );
    return data;
  };

  const campaignInfoQuery = useQuery(
    ['campaignInfo', campaign_id],
    fetchCampaignInfo,
    {
      onSuccess: (data) => {
        setCampaignData({
          campaignName: data?.campaign_name,
          campaignId: data?.campaign_id,
          campaignStatus: data?.campaign_status,
          campaignType: data?.campaign_type,
          campaignStartDate: data?.campaign_start_date,
          campaignEndDate: data?.campaign_end_date,
          campaignPoster: data?.campaign_poster_photo,
          campaignNumberOfSeasons: data?.campaign_number_of_seasons,
          campaignDuration: data?.campaign_duration,
          sampleOfWork: data?.campaign_sample_of_work,
          campaignRightsOwner: data?.campaign_rights_owner,
          campaignCountry: data?.campaign_country,
          campaignServices: data?.campaign_services,
        });

      },
      onError: (error) => {
      }
    }
  );

  

  const CampaignOverallInsightsQuery = useQuery({
    queryKey: ['CampaignOverallInsights', campaign_id],
    queryFn: () => fetchAPI.get(
      process.env.REACT_APP_CUSTOMERS_MANAGEMENT_URL +
      '/analytics/customer/overall/' + getCustomerId() + '?campaign_id=' + campaign_id,
      process.env.REACT_APP_CAMPAIGNS_MANAGEMENT_TOKEN
    )
  });

  const CampaignServicesInsightsQuery = useQuery({
    queryKey: ['CampaignServicesInsights', campaign_id],
    queryFn: () => fetchAPI.get(
      process.env.REACT_APP_CUSTOMERS_MANAGEMENT_URL +
      '/analytics/customer/current-year-per-campaign-service-statistics/' + getCustomerId() + '?campaign_id=' + campaign_id,
      process.env.REACT_APP_CAMPAIGNS_MANAGEMENT_TOKEN
    )
  });

  const DetectedRemovedInsightsQuery = useQuery({
    queryKey: ['DetectedRemovedInsights', campaign_id],
    queryFn: () => fetchAPI.get(
      process.env.REACT_APP_CUSTOMERS_MANAGEMENT_URL +
      '/analytics/customer/detected-removed/' + getCustomerId() + '?campaign_id=' + campaign_id,
      process.env.REACT_APP_CAMPAIGNS_MANAGEMENT_TOKEN
    )
  });

  const DailyMonthlyStatisticsQuery = useQuery({
    queryKey: ['DailyMonthlyStatistics', campaign_id],
    queryFn: () => fetchAPI.get(
      process.env.REACT_APP_CUSTOMERS_MANAGEMENT_URL +
      '/analytics/customer/daily-monthly-statistics/' + getCustomerId() + '?campaign_id=' + campaign_id,
      process.env.REACT_APP_CAMPAIGNS_MANAGEMENT_TOKEN
    )
  });

  const TopRemovalsPerServiceQuery = useQuery({
    queryKey: ['TopRemovalsPerService', campaign_id],
    queryFn: () => fetchAPI.get(
      process.env.REACT_APP_CUSTOMERS_MANAGEMENT_URL +
      '/analytics/customer/top-removal-per-campaign-service/' + getCustomerId() + '?campaign_id=' + campaign_id,
      process.env.REACT_APP_CAMPAIGNS_MANAGEMENT_TOKEN
    )
  });

  //meta title
  document.title = "RH | Rightshero Analytics"


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <CampaignDetail campaignData={campaignData} />
          <Row className="mt-3">
            <h5 className="mb-3">Overall Insights For The Current Year</h5>
            <OverAllInsights data={CampaignOverallInsightsQuery.data} />
          </Row>
          <Row className="mt-3">
            <ServicesData data={CampaignServicesInsightsQuery.data} />
          </Row>
          <Row className="mt-3">
          <Row>
            <Col md={8} >
              <MonthlyDetectedRemoved data={DetectedRemovedInsightsQuery.data} />
            </Col>
            <Col md={4}>
              <TopRemovals todayRemovedLinks={DailyMonthlyStatisticsQuery.data?.today_removed} thisMonthRemovedLinks={DailyMonthlyStatisticsQuery.data?.this_month_removed} topRemovalServices={TopRemovalsPerServiceQuery.data} />
            </Col>  
          </Row>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DashboardSaas
