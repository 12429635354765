import * as XLSX from 'xlsx';


// Generate an Excel file from the data
function generateExcel(data, filename = "data") {
  // Create a new worksheet
  const ws = XLSX.utils.json_to_sheet(data);

  // Create a new workbook with the worksheet
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Data");

  // Generate a blob from the workbook
  XLSX.writeFile(wb, `${filename}.xlsx`);
}

export default generateExcel;
